import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

const navs = [
  {
    id: 1,
    title: "Projects",
    link: "/home",
    avatar: <HomeRoundedIcon />,
  },
  {
    id: 2,
    title: "About",
    link: "/about",
    avatar: <AccountCircleRoundedIcon />,
  },
  {
    id: 3,
    title: "Contact",
    link: "/contact",
    avatar: <CallRoundedIcon />,
  },
  // {
  //   id: 4,
  //   title: "More Projects",
  //   link: "https://www.behance.net/payneoni",
  //   avatar: <MoreVertRoundedIcon />,
  // }
];

export default navs;
