import React, { useState, useEffect } from "react";
import Lists from "../services/apiService";
import "./svg.css";

import useWindowSize from "../services/windowSizeService";

function SvgComponent(props) {
  const [lists, setLists] = useState([]);

  const width = useWindowSize();

  useEffect(() => {
    const fetchLists = async () => {
      const data = await Lists();
      setLists(data);
    };

    fetchLists();
  }, []);

  const currentProject = lists[props.present_number];

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        id="svgmask1"
        viewBox="0 0 1048 827"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        style={{ width: "100%", height: "100%" }}
      >
        <defs>
          <pattern
            id="imgPattern"
            patternUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            {currentProject ? (
              <image
                href={`${currentProject.img}`}
                width="100%"
                height="100%"
                preserveAspectRatio="xMinYMax slice"
              />
            ) : (
              <image
                href="/images/css-pattern-by-magicpattern.png"
                width="100%"
                height="100%"
                preserveAspectRatio="xMinYMax slice"
              />
            )}
          </pattern>
        </defs>

        <path
          d={
            width >= 1060
              ? "M70,677c-38.6599,0-70-31.34-70-70v-371c0-38.66,31.3401-70,70-70h194c38.66,0,70-31.34,70-70v-26c0-38.6599,31.34-70,70-70h494c27.614,0,50,22.4906,50,50.1048v0C948,77.6035,970.292,100,997.791,100v0c27.499,0,49.789,22.292,49.789,49.791v607.209c0,38.66-31.34,70-69.998,70h-343.082c-22.92,0-41.5-18.58-41.5-41.5v0c0-22.92-18.58-41.5-41.5-41.5h-48c-18.502,0-33.5-14.998-33.5-33.5v0c0-18.502-14.998-33.5-33.5-33.5L70,677Z"
              : "M15,0C6.71573,0,0,6.71575,0,15v797c0,8.284,6.71574,15,15,15h1017.58c8.29,0,15-6.716,15-15v-619c0-38.66-31.34-70-69.998-70h-180.082C763.534,123,736,95.4655,736,61.5v0C736,27.5345,708.466,0,674.5,0L15,0Z"
          }
          clipRule="evenodd"
          fill="url(#imgPattern)"
          fillRule="evenodd"
          stroke="#2c2c2c10"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default SvgComponent;
