import "./loader.css";
const Loader = () => {
  return (
    <div className="centrification">
      <div className="🤚">
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="🌴"></div>
        <div className="👍"></div>
      </div>
    </div>
  );
};

export default Loader;
