import React, { useState, useRef } from "react";
import "./home.css";
import SvgComponent from "../components/svg";
import Card from "./card";
import Loader from "./loader";
import useWindowSize from "../services/windowSizeService";
import Multipurpose from "./multipurpose-kit";
import Puppy from "./puppy";
import Content from "./content";
import { Icon } from "@iconify/react";
import ThemeSwitchService from "../services/themeSwitchService";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function Home({ lists, playSound }) {
  const [num, setNum] = useState(0); // Track the index of the first card
  const sliderRef = useRef(0);
  const [theme, setTheme] = useState("");
  const [darkModeOn, setDarkModeOn] = useState(false);
  const windowWidth = useWindowSize();

  gsap.registerPlugin(useGSAP);

  // -------------------------
  // Card switching logic
  //--------------------------

  const handleSlide = (direction) => {
    if (direction === "next") {
      setNum((prevNum) => (prevNum + 1) % lists.length); // Loop to the start if we go past the last card
    } else if (direction === "prev") {
      setNum((prevNum) => (prevNum - 1 + lists.length) % lists.length); // Loop to the end if we go before the first card
    }
  };

  // -------------------------
  // Theme toggling logic
  //--------------------------

  const handleThemeToggle = () => {
    document.body.classList.toggle("dark");
    const newTheme = document.body.classList.contains("dark")
      ? "dark"
      : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    setDarkModeOn(newTheme === "dark");
    ThemeSwitchService.darkModeOn(newTheme === "dark");
  };

  if (lists.length === 0) {
    return <Loader />;
  } else {
    const singleCard = [lists[num + 1]];
    const firstTwoCards = lists.slice(num + 1, num + 3); // Get the first two cards based on `num`

    return (
      <div className="page">
        <div className="left-page">
          <div className="menu-container">
            <Multipurpose
              darkModeOn={handleThemeToggle}
              playSound={playSound}
            />
          </div>
          <SvgComponent present_number={num} siteLink={lists.siteLink} />

          <div className="detail-section-cover">
            <Content currentNum={num} playSound={playSound} />
          </div>
        </div>
        <div className="right-page">
          <div className="c-with-btn">
            <div className="cards-container">
              <div className="row g-4 d-flex" ref={sliderRef}>
                {num < lists.length - 1 ? (
                  // Render the first two cards based on `num` index
                  windowWidth < 760 ? (
                    <Card lists={singleCard} currentNum={num} />
                  ) : (
                    <Card lists={firstTwoCards} currentNum={num} />
                  )
                ) : (
                  // Show a custom message after all cards are viewed

                  <div className="col-lg-12 item">
                    <div className="card h-100 border-0">
                      <div className="card-body px-2 pt-1">
                        <div className="row">
                          <div className="col-12">
                            <h1 style={{ fontSize: "3.4rem" }}>
                              Nothing else to see here. Please let the{" "}
                              <span style={{ color: "#e26538" }}>
                                cutty Puppy
                              </span>{" "}
                              lie.
                            </h1>
                          </div>
                          <div
                            className="col-12"
                            style={{ position: "sticky" }}
                          >
                            <Puppy />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="buttn-container">
              <button
                className="btn-cont"
                onClick={(clickSound) => (
                  playSound(clickSound), handleSlide("prev")
                )}
                disabled={num <= 0}
              >
                <div className="btn-circle">
                  <Icon
                    icon="ic:round-arrow-outward"
                    width="30"
                    height="30"
                    style={{
                      color: "rgb(var(--dark-color))",
                      transform: "rotate(180deg)",
                    }}
                  />
                </div>
                <div className="text"> Prev </div>
              </button>
              <button
                className="btn-cont"
                onClick={(clickSound) => (
                  playSound(clickSound), handleSlide("next")
                )}
                disabled={num === lists.length - 1}
              >
                <div className="text"> NEXT </div>

                <div className="btn-circle">
                  <Icon
                    icon="ic:round-arrow-outward"
                    width="30"
                    height="30"
                    style={{ color: "rgb(var(--dark-color))" }}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
