// import ThemeSwitchService from "../services/themeSwitchService";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { Icon } from "@iconify/react/dist/iconify.js";
import "./multipurpose-kit.css";

function Multipurpose(props) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation().pathname;

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode); // Toggle local state
    props.darkModeOn(); // Call the function passed from parent
  };

  // Function to scroll to a specific section
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      style={{
        position: location !== "/home" && location !== "/contact" && "fixed",
      }}
    >
      <input
        className="hidden-trigger"
        id="toogle"
        type="checkbox"
        onClick={props.playSound}
      />
      <label
        className="ircle"
        htmlFor="toogle"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0",
        }}
      >
        <svg
          viewBox="0 0 48 48"
          version="1.1"
          height="48"
          width="48"
          href="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
          className="svg"
        >
          <Icon
            icon="line-md:plus"
            width="48"
            height="48"
            style={{ color: "rgb(var(--light-color))" }}
          />
        </svg>
        <p
          style={{
            fontSize: "0.9rem",
            fontWeight: 600,
            color: "rgb(var(--light-color))",
            margin: "0",
          }}
        >
          MENU
        </p>
      </label>

      <div className="subs">
        <button className="sub-circle">
          <input
            onChange={toggleDarkMode}
            className="hidden-sub-trigger "
            id="sub1"
            type="checkbox"
            name="sub-circle"
            value="1"
          />
          <label htmlFor="sub1">
            <Icon
              icon="line-md:sunny-filled-loop-to-moon-filled-loop-transition"
              width="2em"
              height="2em"
              style={{
                color: isDarkMode ? "rgb(var(--light-color))" : "",
              }}
            />
          </label>
        </button>
        <button className="sub-circle">
          <input
            className="hidden-sub-trigger"
            id="sub2"
            type="radio"
            name="sub-circle"
            value="1"
          />
          <label htmlFor="sub2">
            <NavLink
              to="/about"
              style={{
                color: isDarkMode ? "rgb(var(--light-color))" : "",
              }}
            >
              About
            </NavLink>
          </label>
        </button>
        <button className="sub-circle" onClick={scrollToTop}>
          <input
            className="hidden-sub-trigger"
            id="sub3"
            type="radio"
            name="sub-circle"
            value="1"
          />
          <label htmlFor="sub3">
            <NavLink
              to="/contact"
              style={{
                color: isDarkMode ? "rgb(var(--light-color))" : "",
              }}
            >
              Contact
            </NavLink>
          </label>
        </button>
      </div>
    </div>
  );
}

export default Multipurpose;
