import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import navs from "./navs";
import { gsap } from "gsap";
import useWindowSize from "../services/windowSizeService";
import "./header.css";
import "./offcanvas.css";
import { useGSAP } from "@gsap/react";
import Menubutton from "./menubutton";

gsap.registerPlugin(useGSAP);

function Header(props) {
  const [scrollY, setScrollY] = useState(0);
  const windowWidth = useWindowSize();
  const [isLight, setIsLight] = useState(false);
  const location = useLocation();
  const [theme, setTheme] = useState(props.theme);

  const [open, setOpen] = useState(false);

  const toggleOffcanvas = () => {
    setOpen((prev) => {
      console.log(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    const isDark = document.querySelector("body").classList.contains("dark");

    if (isDark) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  });

  useEffect(() => {
    let timer;

    if (open) {
      // Apply 'line-light' after .5 seconds
      timer = setTimeout(() => {
        setIsLight(true);
      }, 500);
    } else {
      // Reset to 'line' when 'open' is false
      setIsLight(false);
    }

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
  }, [open]);

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.fromTo(
      ".header",
      {
        y: -600,
        autoAlpha: 0,
      },
      {
        y: 0,
        opacity: 1,
        autoAlpha: 1,
        delay: 1,
      }
    );
  });

  return (
    <>
      <div className={scrollY >= 100 ? "header header_wrap" : "header "}>
        {theme == "dark" ? (
          <NavLink
            to="/landing"
            style={{
              backgroundImage: "none",
            }}
            onClick={(clickSound) => props.playSound(clickSound)}
          >
            <img
              className="mylogo"
              src={"/images/my-logo-light.svg"}
              style={{
                width: windowWidth >= 1060 ? "" : "clamp(6rem, 20vw, 40rem)",
                backgroundImage: "none",
              }}
              alt="my logo"
            />
          </NavLink>
        ) : (
          <NavLink
            to="/landing"
            style={{
              backgroundImage: "none",
            }}
            onClick={(clickSound) => props.playSound(clickSound)}
          >
            <img
              className="mylogo"
              src={"/images/new-logo.svg "}
              style={{
                width: windowWidth >= 1060 ? "" : "clamp(6rem, 20vw, 40rem)",
              }}
              alt="my logo"
            />
          </NavLink>
        )}

        {location.pathname !== "/home" &&
          (windowWidth >= 1060 ? (
            <div className="nav_list">
              {navs.map((nav, index) => {
                return (
                  <NavLink
                    to={nav.link}
                    onClick={(clickSound) => props.playSound(clickSound)}
                    // className="nav_list_link"
                    key={index}
                    target={`${index === 3 ? "_blank" : ""}`}
                    className={({ isActive }) => {
                      return isActive
                        ? "nav_list_link active"
                        : "nav_list_link";
                    }}
                  >
                    {nav.title}
                  </NavLink>
                );
              })}
            </div>
          ) : (
            <Menubutton toggleOffcanvas={toggleOffcanvas} isLight={isLight} playSound={props.playSound} />
          ))}
      </div>
      <div
        className={`page-menu-offcanvas ${
          open && windowWidth < 1060 ? "open" : ""
        }`}
      >
        <div className="page-menu-offcanvas-body">
          {navs.map((nav, index) => {
            return (
              <h1 key={index}>
                <NavLink
                  to={nav.link}
                  onClick={
                    ((clickSound) => props.playSound(clickSound),
                    toggleOffcanvas)
                  }
                  className="nav_list_link text-uppercase"
                  target={`${index === 3 ? "_blank" : ""}`}
                >
                  {index === 4 ? "More Projects" : nav.title}
                </NavLink>
              </h1>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Header;
