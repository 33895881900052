import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./loader";
import Lists from "../services/apiService";
import { gsap } from "gsap/gsap-core";
import { TextPlugin } from "gsap/all";
import { useGSAP } from "@gsap/react";

import "./cover-page.css";

const RedirectComponent = () => {
  gsap.registerPlugin(useGSAP, TextPlugin);
  const navigate = useNavigate();

  useEffect(() => {
    const timer1 = setTimeout(() => {
      navigate("/landing");
    }, 4600);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer1);
  }, [navigate]);

  var tl = gsap.timeline({
    defaults: {
      duration: 1.5,
      ease: "expoScale(0.5,7,none)",
    },
  });

  useGSAP(() => {
    tl.to("#loader-cover", { opacity: 0, duration: 0.5 }, "1.5")
      .to(
        ".main-top, .main-bottom",
        {
          height: 0,
          duration: 0.8,
        },
        "<"
      )
      .to(
        ".img1",
        {
          scale: 1,
        },
        "<"
      )
      .to(
        ".img1",
        {
          visibility: "hidden",
        },
        ">-.2"
      )
      .to(
        ".img2",
        {
          scale: 1,
        },
        "<-.5"
      )
      .to(
        ".img2",
        {
          visibility: "hidden",
        },
        ">-.4"
      )
      .to(
        ".img3",
        {
          scale: 1,
        },
        "<-.5"
      )
      .to(
        ".img3",
        {
          visibility: "hidden",
        },
        ">-.5"
      )
      .to(
        ".img4",
        {
          scale: 1,
        },
        "<-.6"
      )

      // tl.add("test")

      .to(
        ".img4",
        {
          visibility: "hidden",
        },
        ">-.6"
      )
      .to(
        ".img5",
        {
          scale: 1,
        },
        "<-.6"
      )
      .to(
        ".img5",
        {
          visibility: "hidden",
        },
        ">-.6"
      )
      .to(
        ".img6",
        {
          scale: 1,
        },
        "<-.6"
      )
      .to(
        ".img6",
        {
          visibility: "hidden",
        },
        ">-.6"
      );

    // tl.play("test");
  }, [tl]);

  return (
    <div className="main">
      <div className="main-top"></div>
      <div id="loader-cover">
        <Loader />
      </div>
      <div className="cp-image-cover">
        <img
          src="/images/project/expira_images/expira-bg.png"
          alt="cover page first-image"
          className="cp-image img1"
        />
        <img
          src="/images/project/educratic-images/educratic-backdrop.png"
          alt="cover page second-image"
          className="cp-image img2"
        />
        <img
          src="/images/project/polluhuard-images/polluguard.png"
          alt="cover page second-image"
          className="cp-image img3"
        />
        <img
          src=" /images/project/assetkey-images/assetkey-backdrop.png"
          alt="cover page first-image"
          className="cp-image img4"
        />
        <img
          src="/images/project/educratic-images/educratic-backdrop.png"
          alt="cover page second-image"
          className="cp-image img5"
        />
        <img
          src="/images/project/dentaldoor_images/dentaldoor-backdrop.png"
          alt="cover page second-image"
          className="cp-image img6"
        />
      </div>
      <div className="main-bottom"></div>
    </div>
  );
};

export default RedirectComponent;
