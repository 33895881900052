import React, { useState, useEffect } from "react";
import useWindowSize from "../services/windowSizeService";
import Circularbutton from "./circular-button";
import Loader from "./loader";
import { Link } from "react-router-dom";

function Card({lists}) {
  const windowWidth = useWindowSize();

  if (lists === 0) {
    <Loader />;
  } else {
    return (
      <>
        {lists.map((list, index) => (
          <Link to={`/project/${list.id}`} style={{cursor: "pointer !important"}}>
            <div className="col-lg-12 col-auto item" key={index}>
              <div className="card h-100 border-0">
                <div className="card-body px-2 pt-1">
                  <div className="row">
                    <div className="col-9">
                      <h6 className="card-title">
                        <span>{list.name}</span>
                      </h6>
                      <p className="card-text">
                        {list.description.length > 70
                          ? `${list.description.substring(0, 80)}...`
                          : list.description}
                      </p>
                    </div>
                    <div className="col-3 col-3 d-flex align-items-start justify-content-end">
                      <Circularbutton />
                    </div>
                  </div>
                </div>
                <img
                  src={list.img}
                  className="card-img-top"
                  style={{
                    maxHeight: windowWidth < 760 ? "150px" : "220px",
                  }}
                  alt="..."
                />
              </div>
            </div>
          </Link>
        ))}
      </>
    );
  }
}

export default Card;
