import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import RedirectComponent from "./components/cover-page";
import Lists from "./services/apiService";
import Home from "./components/home";
import Projects from "./components/projects";
import useWindowSize from "./services/windowSizeService";
import ContactUs from "./components/contact-us";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./components/about";
import LandingPage from "./components/landing-page";
import ThemeSwitchService from "./services/themeSwitchService";
import "animate.css";

import "./App.css";

function App() {
  const [theme, setTheme] = useState("");
  const [darkModeOn, setDarkModeOn] = useState(false);
  const location = useLocation().pathname;
  const windowWidth = useWindowSize();
  const clickSound = new Audio(
    "/audio/zapsplat_office_pen_ballpoint_click_003_112468.mp3"
  );

  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const playSound = (sound) => {
    sound.currentTime = 0; // Reset the sound
    sound.play();
  };

  // ---------------------
  // Pre fetching api data
  //----------------------

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const data = await Lists();
        setLists(data);
      } catch (error) {
        console.error("Failed to fetch lists:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLists();
  }, []);

  // ---------------------
  // Theme Swithing Logic
  //----------------------

  const pageLoaded = useEffect(() => {
    const themeSwitcher = document.getElementById("theme-switcher");

    function isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    if (!isSafari()) {
      // Add the 'animate-zoom' class for non-Safari browsers
      const imgElement = document.querySelector(".home-inner img");
      if (imgElement) {
        imgElement.classList.add("animate-zoom");
      }
    }

    if (themeSwitcher) {
      loadThemePreference(themeSwitcher);
      themeSwitcher.addEventListener("change", handleThemeToggle);
    }

    return () => {
      if (themeSwitcher) {
        themeSwitcher.removeEventListener("change", handleThemeToggle);
      }
    };
  }, []);

  // -------------------------
  // Check user theme on load
  //--------------------------

  window.addEventListener("load", pageLoaded);

  const loadThemePreference = (themeSwitcher) => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark");
      themeSwitcher.checked = true;
      setTheme("dark");
      setDarkModeOn(true);
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.classList.add("dark");
      themeSwitcher.checked = true;
      setTheme("dark");
      setDarkModeOn(true);
    } else {
      themeSwitcher.checked = false;
      setTheme("");
      setDarkModeOn(false);
    }
  };

  // -------------------------
  // Theme toggling logic
  //--------------------------

  const handleThemeToggle = () => {
    document.body.classList.toggle("dark");
    const newTheme = document.body.classList.contains("dark")
      ? "dark"
      : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    setDarkModeOn(newTheme === "dark");
    ThemeSwitchService.darkModeOn(newTheme === "dark");
  };

  // ----------------------------
  // Mouse follower circle logic
  //-----------------------------

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // --------------------------------------------------------------------
  // Mouse follower turns bigger with and eye in the center circle logic
  //---------------------------------------------------------------------

  useEffect(() => {
    const anchor = document.querySelectorAll("a");
    const ball = document.querySelector(".mouse-follower");
    const ballHidder = document.querySelector("a .mylogo");
    const eyePopper = document.querySelector(".eye-popper");

    if (!eyePopper) {
      // console.warn(".tester element not found.");
      return;
    }

    const dimensions = eyePopper.getBoundingClientRect();

    const handleMouseMove = (event) => {
      const { clientX: mouseX, clientY: mouseY } = event;

      // Check if the mouse is inside the eyePopper
      const isInside =
        mouseX > dimensions.left &&
        mouseX < dimensions.right &&
        mouseY > dimensions.top &&
        mouseY < dimensions.bottom;

      if (isInside) {
        ball.classList.add("bigger-with-eye");
      } else {
        ball.classList.remove("bigger-with-eye");
      }
    };

    // Attach mousemove event to the document
    document.addEventListener("mousemove", handleMouseMove);

    if (ballHidder) {
      ballHidder.addEventListener("mouseover", () => {
        ball.classList.add("hide-ball");
      });

      ballHidder.addEventListener("mouseout", () => {
        ball.classList.remove("hide-ball");
      });
    }

    anchor.forEach((element) => {
      element.addEventListener("mouseover", () => {
        ball.classList.add("bigger");
      });

      element.addEventListener("mouseout", () => {
        ball.classList.remove("bigger");
      });
    });
  });

  return (
    <div>
      {location !== "/cover" && (
        <Header
          theme={theme}
          darkModeOn={darkModeOn}
          playSound={() => {
            playSound(clickSound);
          }}
        />
      )}

      <Routes>
        <Route path="/" element={<Navigate to="/cover" />} />
        <Route path="/cover" element={<RedirectComponent />} />
        <Route
          path="/landing"
          element={
            <LandingPage
              lists={lists}
              isLoading={isLoading}
              playSound={() => {
                playSound(clickSound);
              }}
            />
          }
        />
        <Route
          path="/home"
          element={
            <Home
              lists={lists}
              playSound={() => {
                playSound(clickSound);
              }}
            />
          }
        />
        <Route path="/project/:id" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>

      {location === "/landing" && (
        <div
          className={`mouse-follower ${windowWidth > 500 ? "" : "d-none"}`}
          style={{
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
          }}
        ></div>
      )}

      {/* {location === "/home" && <Multipurpose darkModeOn={handleThemeToggle} />} */}
      {location === "/landing" && "/about" && <Footer />}
    </div>
  );
}

export default App;
