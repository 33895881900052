import React, { useState, useEffect } from "react";
import Lists from "../services/apiService";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import Circularbutton from "./circular-button";

function Content(props) {
  const [proList, setProLists] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await Lists();
      setProLists(data);
    };

    fetchData();
  }, []);



  const project = proList[props.currentNum] || {};
  

  if (!project || !project.name) {
    return (
      <div className="content">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Skeleton
            width="40%"
            height={100}
            style={{
              backgroundColor: "rgba(var(--dark-color), 0.1)",
              marginBottom: -26,
            }}
          />
          <Skeleton
            width="40%"
            height={100}
            style={{
              backgroundColor: "rgba(var(--dark-color), 0.1)",
            }}
          />
        </Box>
      </div>
    );
  }

  return (
    <>
      <div className="detail-section">
        <h1>
          <strong
            style={{ fontSize: "clamp(2rem, 15vw, 4.5rem)", fontWeight: "500" }}
          >
            {project.name}
          </strong>{" "}
          <span
            style={{ fontSize: "clamp(1rem, 10vw, 3rem)", fontWeight: "300" }}
          >
            {project.year}
          </span>
        </h1>
      </div>
      <div className="row details gap-md-0 gap-5">
        <div className="col-md-8 col-12 p-0">
          <div style={{ fontSize: "1.1rem", fontWeight: "400" }}>
            {`${project.description.substring(0, 150)}...`}
          </div>
        </div>
        <div className="col-md-4 col-12 align-self-center p-0 px-md-2">
          <Link
            to={`/project/${project.id}`}
            onClick={ props.playSound }
          >
            <div className="spherical-btn" type="button">
              DESIGN PROCESS
              <OpenInFullOutlinedIcon />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Content;
