import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import FigmaEffect2 from "./figma-effect-2";
import FigmaEffect from "./figma-effect";

import "./landing-page.css";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function LandingPage({ lists, isLoading, playSound }) {
  useGSAP(() => {
    if (isLoading || lists.length === 0) return;

    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.9,
          ease: "expoScale(10,2.5,power2.out)",
          opacity: 0,
        },
      });

      tl.fromTo(
        ".inner-grid-item:not(:nth-child(5))",
        {
          scale: 50,
          zIndex: 12,
        },
        {
          scale: 1,
          autoAlpha: 1,
          transformOrigin: "25% 50%",
          // delay: 0,
        }
      )
        .to(
          ".inner-grid-item",
          {
            scale: 1,
            autoAlpha: 1,
            stagger: 0.5,
          },
          "-=.5"
        )
        .fromTo(
          ".item1",
          {
            xPercent: -10,
          },
          {
            xPercent: 0,
            autoAlpha: 1,
          },
          "<.5"
        )
        .fromTo(
          ".item2",
          {
            yPercent: -10,
          },
          {
            yPercent: 0,
            autoAlpha: 1,
          },
          "<.2"
        )
        .fromTo(
          ".item4",
          {
            yPercent: 10,
          },
          {
            yPercent: 0,
            autoAlpha: 1,
          },
          "<.2"
        )
        .fromTo(
          ".item5",
          {
            xPercent: 10,
          },
          {
            xPercent: 0,
            autoAlpha: 1,
          },
          "<"
        )
        .fromTo(
          ".inner-grid-item:nth-child(5)",
          {
            opacity: 0,
            scale: 0,
          },
          {
            scale: 1,
            autoAlpha: 1,
            ease: "back.out(2)",
          },
          "<.5"
        );
    });

    return () => ctx.revert();
  }, [isLoading]);

  return (
    <>
      <div class="figma-effect">
        <FigmaEffect />
      </div>

      <div className="grid-container">
        <div className="item1">
          <div className="year">©2025</div>

          <div className="location">
            <p className="location-heading">
              <Icon
                icon="material-symbols-light:location-on-outline-rounded"
                width="24"
                height="24"
              />{" "}
              Location:
            </p>
            <p className="location-content">Dartford, Kent, United Kingdom.</p>
          </div>

          <div className="location">
            <p className="location-heading">
              <Icon
                icon="material-symbols-light:work-history-outline-rounded"
                width="24"
                height="24"
              />{" "}
              Current Position:
            </p>
            <p className="location-content">
              Currently working with MTICBIA!! in the United Kingdom, London.
            </p>
            <p className="location-content">
              Contractually working with Byteworks LTD in Nigeria, Lagos.
            </p>
          </div>
        </div>

        <div className="item2">
          <div className="intro-title">I'M SOLOMON</div>
          <h1>
            Cra
            <span>
              <img
                src="images/figma3d.png"
                style={{
                  width: "3rem",
                  textAlign: "center",
                }}
                alt=""
              />
            </span>
            ting{" "}
            <span style={{ fontWeight: "600" }}>
              user
              <span>
                <img
                  src="images/target.svg"
                  style={{
                    width: "3rem",
                    textAlign: "center",
                  }}
                  alt=""
                />
              </span>
              centric{" "}
            </span>{" "}
            <span style={{ color: "rgb(var(--accent-color)) " }}>
              desi
              <span>
                <img
                  src="images/blender.png"
                  style={{
                    width: "3rem",
                    textAlign: "center",
                  }}
                  alt=""
                />
              </span>
              ns{" "}
            </span>{" "}
            and{" "}
            <span style={{ color: "rgb(var(--accent-color)) " }}>
              front-end{" "}
            </span>
            solutions since <span className="end"> 2017</span>
            <span>
              <img src="images/mac.avif" className="mac-icon" alt="" />

              <img src="images/star.svg" className="star-icon" alt="" />
            </span>
          </h1>
          <div className="eye-popper"></div>
        </div>

        <div className="item3">
          <div className="inner-grid-title">featured works</div>
          <div className="inner-grid-container">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0",
                }}
              >
                <p></p>
              </div>
            ) : (
              lists.slice(0, 4).map((list, index) => (
                <div className="inner-grid-item" key={index}>
                  <img src={list.img} alt="" />
                </div>
              ))
            )}
            <div className="inner-grid-item b-hider">
              <Link to="/home" style={{ fontFamily: "poppins" }}>
                <button
                  type="button"
                  onClick={(clickSound) => playSound(clickSound)}
                >
                  {" "}
                  PROJECTS
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="item4">
          <div className="skills-container">
            <div className="skills-title">
              <div>
                Sometimes I shape-shift from a designer by day to a developer by
                night.
              </div>
              <div>I AM CURRENTLY SKILLED IN:</div>
            </div>

            <div className="skills-content">
              <div className="skill">EMPATHIZING</div>
              <div className="skill">USER RESEARCH</div>
              <div className="skill">AR/VR DESIGN</div>
              <div className="skill">UI/VISUAL</div>
              <div className="skill">E-LEARNING DESIGN</div>
              <div className="skill">WIREFRAMING</div>
              <div className="skill">PROTOTYPING</div>
              <div className="skill">LOGO</div>
              <div className="skill">AND</div>
              <div className="skill">NODE JS</div>
              <div className="skill">POSTGRE SQL</div>
              <div className="skill">WEB3 DApp</div>
              <div className="skill">JAVASCRIPT</div>
              <div className="skill">ANGULAR JS</div>
              <div className="skill">REACT JS</div>
              <div className="skill">HTML/CSS</div>
            </div>
          </div>
        </div>

        <div className="item5">
          <img src="/images/empathize.svg" alt="my line art" />
        </div>
      </div>
    </>
  );
}

export default LandingPage;
