import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Circularbutton = () => {
  

  return (
    <StyledWrapper>
      <Link >
        <div className="circular-btn" type="button">
          <svg
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="circular-btn__icon-svg"
            width="18"
          >
            <path
              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
              fill="currentColor"
            ></path>
          </svg>

          <svg
            viewBox="0 0 14 15"
            fill="none"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
            className="circular-btn__icon-svg circular-btn__icon-svg--copy"
          >
            <path
              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </Link>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .circular-btn {
    flex-shrink: 0;
    position: relative;
    color: rgb(var(--light-color));
    cursor: pointer;
    background-color: rgb(var(--dark-color), .95);
    width: clamp(30px, 50px, 60px);
    height: clamp(30px, 50px, 60px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .circular-btn__icon-svg--copy {
    position: absolute;
    transform: translate(-150%, 150%);
  }

  .circular-btn:hover .circular-btn__icon-svg:first-child {
    transition: transform 0.2s ease-in-out;
    transform: translate(150%, -150%);
  }

  .circular-btn:hover .circular-btn__icon-svg--copy {
    transition: transform 0.2s ease-in-out 0.1s;
    transform: translate(0);
  }
`;

export default Circularbutton;
